<template>
  <v-container class="cts-main-container cts-main-view col-12 pa-0">
    <v-col class="ma-0 pa-0 " cols="12">
      <v-row class="ma-0" dense>
        <v-col class=" mx-auto cts-view-header" cols="11" lg="10" md="11" sm="11" xl="10">
          <v-row dense>
            <v-col cols="12">
              <view-title
                :icon="const_icons.TRANSACTIONS"
                :subtitle="$vuetify.lang.t('$vuetify.transactionsDescription')"
                :title="$vuetify.lang.t('$vuetify.transactionsTitle')"
                idName="transactions"
              >
                <template v-if="totalPendingTransactions !== 0" v-slot:actions>
                  <v-alert v-if="!showPendingTransactions &&  $vuetify.breakpoint.mdAndUp" class="ma-0"
                           color="var(--semitransparent)" dense outlined
                           text>
                    <v-icon class="mr-2" color="var(--primary-alternative)" size="30">
                      schedule
                    </v-icon>
                    <span
                      v-if="!showPendingTransactions" class="mr-2 cts-font-size-3 cts-color-darken">{{
                        totalPendingTransactions + ' ' + $vuetify.lang.t('$vuetify.pendingTransactions')
                      }}</span>

                    <v-btn
                      id="digital-signature-confirm_reminder_btn"
                      class="cts-color-primary-alternative"
                      depressed
                      small
                      @click="showPendingTransactions = !showPendingTransactions">
                      {{$vuetify.lang.t('$vuetify.see')}}
                    </v-btn>
                  </v-alert>
                  <v-btn
                    v-else-if=" $vuetify.breakpoint.smAndDown"
                    id="digital-signature-confirm_reminder_btn"
                    class="cts-color-primary-alternative"
                    depressed
                    small
                    @click="showPendingTransactions = !showPendingTransactions">
                    <v-icon class="mr-1">{{showPendingTransactions ? 'arrow_back' : 'schedule'}}</v-icon>
                    {{showPendingTransactions ? $vuetify.lang.t('$vuetify.back') : totalPendingTransactions}}
                  </v-btn>
                  <v-btn
                    v-else
                    id="digital-signature-confirm_reminder_btn"
                    class="cts-color-primary-alternative"
                    depressed
                    small
                    @click="showPendingTransactions = !showPendingTransactions">
                    <v-icon class="mr-1">arrow_left</v-icon>
                    {{$vuetify.lang.t('$vuetify.back')}}
                  </v-btn>
                </template>
              </view-title>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0 justify-center" dense>
        <v-col class="pa-0" cols="11" lg="10" md="11" xl="10">
          <v-row class="py-2 datatable-filter-bar" dense>
            <v-text-field
              v-if="!showPendingTransactions"
              id="batches-input-search"
              v-model="search"
              :aria-label="$vuetify.lang.t('$vuetify.fastSearch')"
              :placeholder="$vuetify.lang.t('$vuetify.fastSearch')"
              :prepend-inner-icon="const_icons.SEARCH"
              :style="$vuetify.breakpoint.smAndDown ? 'min-width: 98% !important' : ''"
              aria-autocomplete="none"
              class="ma-0 cts-filter-text"
              clearable
              dense
              hide-details
              single-line
              solo
              @blur="reloadDatatable(null)"
              @keydown.enter="reloadDatatable(null)"
              @click:clear="clearSearch"
            ></v-text-field>
            <DateFilter v-if="!showPendingTransactions" :model-value="filters.date" @change="changeFilterDate"/>
            <v-autocomplete
              id="batches-input-process"
              v-model="filters.process_code"
              :aria-label="$vuetify.lang.t('$vuetify.processFilterPlaceholder')"
              :items="availableProcesses"
              :placeholder="$vuetify.lang.t('$vuetify.processFilterPlaceholder')"
              :prepend-inner-icon="const_icons.PROCESSES"
              :style="$vuetify.breakpoint.smAndDown ? 'min-width: 98% !important' : ''"
              class="ma-0 cts-filter-text cts-medium-filter"
              clearable
              dense
              hide-details
              item-text="name"
              item-value="code"
              single-line
              solo
              @change="reloadDatatable(null)"
            ></v-autocomplete>
            <v-tooltip bottom open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <div
                  :style="$vuetify.breakpoint.smAndDown ? 'min-width: 100% !important' : ''"
                  v-bind="attrs"
                  v-on="on">
                  <v-select
                    v-if="!showPendingTransactions"
                    id="transactions-input-status"
                    v-model="filters.macrostatus"
                    :aria-label="$vuetify.lang.t('$vuetify.stateFilterPlaceholder')"
                    :class="filters.process_code ? 'cts-medium-filter' : 'cts-small-filter'"
                    :disabled="filters.process_code === null"
                    :items="availableStatuses"
                    :placeholder="$vuetify.lang.t('$vuetify.stateFilterPlaceholder')"
                    :prepend-inner-icon="const_icons.STATUS"
                    :style="$vuetify.breakpoint.smAndDown ? 'min-width: 98% !important' : ''"
                    class="ma-0 cts-filter-text "
                    clearable
                    dense
                    hide-details
                    item-text="description"
                    item-value="code"
                    single-line
                    solo
                    @change="reloadDatatable(null)"
                  >
                    <!-- Show items with first letter upper -->
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>{{item.description.charAt(0).toUpperCase() + item.description.slice(1)}}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>

                  </v-select>

                </div>
              </template>
              <span>
                {{filters.process_code ? $vuetify.lang.t('$vuetify.stateFilterPlaceholder') : $vuetify.lang.t('$vuetify.selectProcessFirst')}}
              </span>
            </v-tooltip>
            <v-autocomplete
              v-if="!showPendingTransactions && availableGroups && availableGroups.length > 1"
              id="transactions-input-process"
              v-model="filters.group_id"
              :aria-label="$vuetify.lang.t('$vuetify.group')"
              :items="availableGroups"
              :placeholder="$vuetify.lang.t('$vuetify.group')"
              :style="$vuetify.breakpoint.smAndDown ? 'min-width: 98% !important' : ''"
              class="ma-0 cts-filter-text cts-small-filter "
              clearable
              dense
              hide-details
              item-text="name"
              item-value="id"
              prepend-inner-icon="people"
              single-line
              solo
              @change="reloadDatatable(null)"
            ></v-autocomplete>

            <v-col class="text-right align-content-center px-1 px-md-2">
              <v-btn
                :loading="loading"
                class="cts-font-size-5 cts-bgcolor-primary-alternative cts-color-clear"
                right
                @click="reloadDatatable(null,true)"
              >
                <v-icon size="18">
                  refresh
                </v-icon>
                {{$vuetify.lang.t('$vuetify.refresh')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="ma-0" dense justify="center">
        <v-col v-if="availableTransactions" class="pa-0 px-1" cols="11" lg="10" md="11" xl="10" xs="11">
          <TransactionsDatatable
            :available-statuses="availableStatuses"
            :available-transactions="showPendingTransactions ? pendingTransactions : availableTransactions"
            :current-location="currentLocation"
            :frontOptions="frontOptions"
            :loading="loading"
            :totalRecords="showPendingTransactions ? totalPendingTransactions : totalRecords"
            :view-type="showPendingTransactions ? 'scheduled' : 'sent'"
            @cancelTransaction="cancelTransaction"
            @openBatchDetail="openBatchDetail"
            @openTransaction="openTransaction"
            @updateOptions="updateDataTableOptions"
          ></TransactionsDatatable>
        </v-col>
      </v-row>
    </v-col>
    <ConfirmDialog ref="confirm_action"/>
  </v-container>
</template>
<script>
import ViewTitle from "@/components/structures/viewTitle.vue";
import const_icons from "@/constants/icons";
import const_global from "@/constants/global";
import {find} from "@/services/finderService"
import ConfirmDialog from "@/components/structures/ConfirmDialog.vue";
import {mapGetters} from "vuex";
import {GETTERS as LOCATION_GETTERS, NAMESPACE as LOCATION_NAMESPACE} from "@/constants/vuex/location";
import DateFilter from "@/components/structures/datatables/filters/DateFilter.vue";
import dayjs from "dayjs";
import {updateQueryParams} from "@/util/utils";
import TransactionsDatatable from "@/components/sections/transactions/TransactionsDataTable.vue";
import {cancelScheduledTransaction, getTransactionsSearchFilters} from "@/services/transactionServices";
import {getProcessMacrostatuses} from "@/services/processServices";
import {ACTIONS as TRANSACTIONS_ACTIONS, NAMESPACE as TRANSACTIONS_NAMESPACE} from "@/constants/vuex/transactions";

export default {
  name: "Transactions",
  components: {
    TransactionsDatatable,
    DateFilter,
    ConfirmDialog,
    ViewTitle
  },
  data() {
    return {
      const_global,
      const_icons,
      availableStatuses: [],
      availableProcesses: [],
      search: '',
      fields: [],
      filters: {
        date: [],
        macrostatus: null,
        process_code: null,
        group_id: null
      },
      backFilters: null,
      availableTransactions: [],
      frontOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["creation_timestamp"],
        sortDesc: [
          true
        ],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false
      },
      totalRecords: 0,
      batchSelected: null,
      loading: false,
      pendingTransactions: [],
      totalPendingTransactions: 0,
      showPendingTransactions: false,
      availableGroups: []
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: `${LOCATION_NAMESPACE}/${LOCATION_GETTERS.G_CURRENT_LOCATION}`,
    }),
  },
  created() {
    this.fillProcessesAndLinkedGroups()
    this.initializeFiltersFromRouteOrLocalStorage()
    this.getPendingTransactions()
  },
  watch: {
    showPendingTransactions(value) {
      if (value) {
        this.getPendingTransactions()
      } else {
        this.getTransactions(null, true)
      }

    }
  },
  methods: {
    initializeFiltersFromRouteOrLocalStorage() {
      let query = this.$route.query;
      if (Object.keys(query).length === 0 && localStorage.getItem('lastQueryTransactions')) {
        query = JSON.parse(localStorage.getItem('lastQueryTransactions'))
      }
      this.filters.date = query.date ? query.date.split(",") : [];
      this.filters.macrostatus = query.macrostatus || null;
      this.filters.process_code = query.process_code || null;
      this.filters.group_id = query.group_id || null;
      this.search = query.search || "";
      this.frontOptions.page = query.page ? parseInt(query.page) : 1;
      this.frontOptions.itemsPerPage = query.rpp ? parseInt(query.rpp) : 10;
      this.frontOptions.sortBy = query.sort ? [query.sort] : ["creation_timestamp"];
      this.frontOptions.sortDesc = query.sortDesc ? [query.sortDesc === "true"] : [true];

    },
    updateRouteWithFilters(filters) {
      const query = {
        ...this.$route.query,
        date: filters.date && filters.date.length === 2 ? filters.date.join(",") : undefined,
        macrostatus: filters.macrostatus || undefined,
        process_code: filters.process_code || undefined,
        group_id: filters.group_id || undefined,
        search: this.search ? this.search : undefined,
        page: this.frontOptions.page,
        rpp: this.frontOptions.itemsPerPage,
        sort: this.frontOptions.sortBy[0],
        sortDesc: this.frontOptions.sortDesc[0] ? "true" : "false"
      };
      updateQueryParams(query)
      localStorage.setItem('lastQueryTransactions', JSON.stringify(query))
    },
    changeFilterDate(dates) {
      dates.sort((a, b) => dayjs(a).unix() - dayjs(b).unix())
      this.filters.date = dates
      this.getTransactions(null, false)
    },
    fillProcessesAndLinkedGroups() {
      getTransactionsSearchFilters().then(response => {
        this.availableProcesses = response.data.processes.map(process => {
          return {code: process.code, name: process.name}
        }).sort((a, b) => a.name.localeCompare(b.name))
        this.availableGroups = response.data.linked_groups.map(group => {
          return {id: group.id, name: group.name}
        }).sort((a, b) => a.name.localeCompare(b.name))
      })
    },
    fillMacrostatuses() {
      getProcessMacrostatuses(this.filters.process_code).then(response => {
        if (response.data.extra) {
          response.data.extra.push({
            code: 'TRANSACTION.canceled',
            description: this.$vuetify.lang.t('$vuetify.transaction_cancelled')
          })
          response.data.extra.push({code: 'queued', description: this.$vuetify.lang.t('$vuetify.pending')})
          this.availableStatuses = response.data.extra.map(status => {
            return {code: status.code, description: status.description}
          }).sort((a, b) => a.description.localeCompare(b.description))
        } else {
          this.availableStatuses = []
        }
      })
    },
    async updateDataTableOptions(options) {
      this.frontOptions = options
      await this.getTransactions(options)
    },
    reloadDatatable(newFrontOptions, refresh) {
      if (this.showPendingTransactions) {
        this.getPendingTransactions(newFrontOptions, refresh)
      } else {
        this.getTransactions(newFrontOptions, refresh)
      }
    },
    async getTransactions(newFrontOptions, refresh = false) {
      this.loading = true
      if (newFrontOptions) {
        this.frontOptions = newFrontOptions
      } else {
        this.frontOptions.page = 1
      }
      const backOptions = {
        rpp: this.frontOptions.itemsPerPage,
        page: this.frontOptions.page,
        order: {
          field: this.frontOptions.sortBy[0],
          direction: this.frontOptions.sortDesc.length === 0 ? 'ASC' : this.frontOptions.sortDesc[0] ? 'DESC' : 'ASC'
        }
      }
      const newBackFilters = {
        macrostatus: this.filters.macrostatus || undefined,
        process_code: this.filters.process_code || undefined,
        date: this.filters.date || undefined,
        ['source_extra.group']: this.filters.group_id || undefined
      };
      if (this.search) {
        newBackFilters['__full_search'] = this.search
      } else {
        delete newBackFilters['__full_search']
      }
      if (this.filters.process_code !== null) {
        this.fillMacrostatuses()
      }

      if (refresh || newFrontOptions || JSON.stringify(newBackFilters) !== JSON.stringify(this.backFilters)) {
        this.backFilters = newBackFilters
        this.updateRouteWithFilters(this.backFilters);

        if (this.filters.date && this.filters.date.length === 2) {
          newBackFilters['creation_timestamp'] = {
            type: 'custom',
            from: dayjs(this.filters.date[0]).startOf('day').unix(),
            to: dayjs(this.filters.date[1]).endOf('day').unix()
          }
          delete newBackFilters.date
        } else {
          delete newBackFilters['creation_timestamp']
          delete newBackFilters.date
        }

        find('/transactions/search', this.fields, newBackFilters, backOptions).then(data => {
          data.extra.results.forEach(transaction => {
            transaction.showAllInterveners = false
          })
          this.availableTransactions = data.extra.results
          this.totalRecords = data.extra.count
          this.loading = false
        })
      }
    },
    getPendingTransactions() {
      const backOptions = {
        rpp: this.frontOptions.itemsPerPage,
        page: this.frontOptions.page,
        order: {
          field: 'schedule_time',
          direction: this.frontOptions.sortDesc.length === 0 ? 'ASC' : this.frontOptions.sortDesc[0] ? 'DESC' : 'ASC'
        }
      }
      let newBackFilters = {
        process_code: this.filters.process_code || undefined,
      };
      find('/transactions/scheduled', this.fields, newBackFilters, backOptions).then(data => {
        this.pendingTransactions = data.extra.results
        this.totalPendingTransactions = data.extra.count
        this.loading = false
      })
    },
    clearSearch() {
      this.search = ''
      this.getTransactions()
    },
    openBatchDetail(batch) {
      this.batchSelected = batch
    },
    async openTransaction(instance_code) {
      await this.$store.dispatch(`${TRANSACTIONS_NAMESPACE}/${TRANSACTIONS_ACTIONS.A_SET_TRANSACTION_SELECTED}`, instance_code)
      await this.$router.push({name: 'transaction'})
    },

    async cancelTransaction(instance_code) {
      if (
        await this.$refs.confirm_action.open(
          null,
          this.$vuetify.lang.t('$vuetify.cancelTransactionAdvertisement'),
          {
            width: "600px",
            cancelText: this.$vuetify.lang.t('$vuetify.back'),
            confirmText: this.$vuetify.lang.t('$vuetify.cancelTransaction')
          }
        )
      ) {
        cancelScheduledTransaction(instance_code).then(() => {
          this.reloadDatatable(null, true)
        })

      }
    },
  }
}
</script>

<style lang="scss">
.pending-transactions-info-button {
  max-width: unset !important;
}


</style>